// ** Third Party Components
import styled from "styled-components";

// ** Styles Import
import { breakpoints } from "styles/grid";

// ** Custom Components
import Block from "components/layout/Block";

// #####################################################

export const StyledInnerBlock = styled(Block)`
	width: 100%;
	height: 100%;
	justify-content: space-between;
	display: flex;
	align-items: center;

	@media (min-width: ${breakpoints.desktop}px) {
		padding: 0px 0px 0 1.5rem;
	}
`;

// #####################################################
