import React from "react";

// ** Third Party Components
import styled, { css } from "styled-components";

// ** Custom Components
import Button from "components/base/Button";
import BtnContent from "../Btn/components/BtnContent";
import Image from "components/layout/Image";
import Avatar from "components/layout/Avatar";
import Text from "components/typography/Text";
import Pill from "components/layout/Pill";

// ** Custom Styles
import { breakpoints } from "styles/grid";
import { resolveProps, marginable } from "styles/styledComponentsMixins";

// #####################################################

const StyledButtonRoundedHeader = styled(({ ...props }) => (
	<Button {...resolveProps(props, marginable)} />
))(({ active }) => {
	return css`
		position: relative;
		display: inline-block;
		border-radius: var(--dz-sys-button-rounded-border-radius);
		font-size: 1rem;
		padding: 0.4rem 0.75rem;
		height: 38px;
		width: 38px;
		line-height: 26px;
		background-color: var(--dz-sys-button-rounded-background-color);
		color: var(--dz-sys-button-rounded-text-color);

		${marginable.css}

		&:hover,
        &:focus {
			background-color: var(
				--dz-sys-button-rounded-background-color-hover
			);
		}

		&:active {
			background-color: var(
				--dz-sys-button-rounded-background-color-active
			);
		}

		@media (min-width: ${breakpoints.desktop}px) {
			height: 40px;
			line-height: 28px;
		}

		${active &&
		css`
			background-color: var(
				--dz-sys-button-rounded-selected-background-color
			);

			&:hover,
			&:focus {
				background-color: var(
					--dz-sys-button-rounded-selected-background-color-hover
				);
			}

			&:active {
				background-color: var(
					--dz-sys-button-rounded-selected-background-color-active
				);
			}
		`}

		${({ userProfile }) =>
			userProfile &&
			css`
				border-radius: var(--dz-sys-button-border-radius);
				padding: 0;
				width: auto;

				${Avatar.Container} {
					height: 36px;
					width: 36px;
				}
				${Image} {
					height: 36px;
					width: 36px;
				}

				${Text} {
					color: ${({ theme: { colors } }) => colors.primary};
					padding: 0 0 0 0.5rem;
					vertical-align: middle;
				}

				@media (max-width: ${breakpoints.phone - 1}px) {
					${Text} {
						display: none;
					}
				}
			`}

		${Pill} {
			position: absolute;
			transform: translateX(100%);
			top: 0px;
		}
	`;
});

// #####################################################

export const ButtonRoundedHeader = ({
	active,
	textLeft,
	iconType,
	iconTypeActive,
	icon,
	iconOnly,
	iconSize,
	iconComponent,
	svgIconComponent,
	svgIconComponentActive,
	dropdown,
	alignItemsStart,
	noPointerEffect,
	label,
	children,

	...rest
}) => {
	const textContent = children || label;

	return (
		<StyledButtonRoundedHeader active={active} {...rest}>
			<BtnContent
				noPointerEffect={noPointerEffect}
				active={active}
				textLeft={textLeft}
				textContent={textContent}
				iconType={iconType}
				iconTypeActive={iconTypeActive}
				icon={icon}
				iconOnly={iconOnly}
				iconSize={iconSize}
				iconComponent={iconComponent}
				svgIconComponent={svgIconComponent}
				svgIconComponentActive={svgIconComponentActive}
				dropdown={dropdown}
				alignItemsStart={alignItemsStart}
			/>
		</StyledButtonRoundedHeader>
	);
};

// #####################################################
