// ** Third Party Components
import styled from "styled-components";

// ** Styles Import
import { breakpoints } from "styles/grid";

// #####################################################

export const StyledSearchWrapper = styled.div`
	position: relative;

	@media (max-width: ${breakpoints.desktop - 1}px) {
		width: 100%;
	}
`;

// #####################################################
