// ** React Imports
import { memo } from "react";

// ** Third Party Components
import styled, { css } from "styled-components";

// ** Custom Components
import Icon from "components/global/Icon";

// #####################################################

const TopMenuItemWrapperStyled = styled.div`
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 100%;
`;

const TopMenuNavigationButtonStyled = styled.button`
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	display: inline-flex;
	color: var(--dz-sys-text-color);
	width: 100%;
	height: 100%;
	position: relative;
`;

const TopMenuNavigationButtonContentStyled = styled.div`
	justify-content: start;
	align-items: center;
	height: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 8px;
	position: relative;

	${({ isActive }) =>
		isActive &&
		css`
			color: ${({ theme: { colors, isDarkMode } }) =>
				isDarkMode
					? colors.primaryLight
					: "var(--dz-ref-color-primary400)"};
		`}
`;

// #####################################################

const compareProps = (prevProps, nextProps) => {
	return prevProps.isActive === nextProps.isActive;
};

// #####################################################

const TopMenuButton = ({
	icon,
	iconActive,
	isActive,
	triggerProps,
	onClick,
}) => {
	// #####################################################

	return (
		<TopMenuItemWrapperStyled {...triggerProps}>
			<TopMenuNavigationButtonStyled
				onClick={() => {
					if (typeof onClick === "function") {
						onClick();
					}
				}}
			>
				<TopMenuNavigationButtonContentStyled isActive={isActive}>
					{isActive ? (
						<Icon
							svgIconComponent={iconActive}
							icon="user-circle"
							type="fas"
							height="21px"
							width="100%"
							isActive={isActive}
						/>
					) : (
						<Icon
							svgIconComponent={icon}
							icon="user-circle-regular"
							type="far"
							height="21px"
							width="100%"
						/>
					)}
				</TopMenuNavigationButtonContentStyled>
			</TopMenuNavigationButtonStyled>
		</TopMenuItemWrapperStyled>
	);
};

// #####################################################

export default memo(TopMenuButton, compareProps);

// #####################################################
