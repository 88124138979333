// ** React Imports
import { useContext } from "react";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import loadable from "@loadable/component";

// ** Custom Components
import HamburgerMenuButton from "./components/HamburgerMenuButton";
import Dropdown from "components/layout/Dropdown";

// ** Custom Hooks
import useOpenClose from "hooks/useOpenClose";
import useBreakpointWidth from "hooks/useBreakpointWidth";

// ** Contexts
import deviceTypeContext from "contexts/deviceTypeContext";
import { DropdownTriggerContext } from "components/layout/Dropdown/DropdownTriggerContext";

// ** Constants
import { topHeaderHeight } from "styles/declares";

// ** Lazy Loaded Components
const HamburgerMenuContent = loadable(
	() => import("./components/HamburgerMenuContent"),
	{
		ssr: false,
	}
);

// #####################################################

const MAX_DESKTOP_WIDTH = "240px";

// #####################################################

const HamburgerMenu = () => {
	const { isTablet, isPhone } = useContext(deviceTypeContext);

	const breakpointWidth = useBreakpointWidth();

	const isClient = useSelector((state) => state.global.isClient);

	const { isOpen, close, toggle } = useOpenClose();

	const extendedClose = ({ insideUseEffect = false } = {}) => {
		if (insideUseEffect) {
			close();
		} else {
			close(() => {
				if (document.body.style.overflow === "hidden") {
					document.body.style.overflow = "unset";
				}
			});
		}
	};

	const extendedToggle = () => {
		toggle(() => {
			if (
				!isOpen &&
				isPhone &&
				document.body.style.overflow !== "hidden"
			) {
				document.body.style.overflow = "hidden";
			} else if (isOpen && document.body.style.overflow === "hidden") {
				document.body.style.overflow = "unset";
			}
		});
	};
	const useLayerOptions = {
		onOutsideClick: () => {
			!isPhone && typeof close === "function" && extendedClose();
		},
		placement: "bottom-start",
		triggerOffset: 10,
		container: isClient ? "app" : "root",
	};

	const customLayerStyle = {
		position: "fixed",
		width: MAX_DESKTOP_WIDTH,
		overflowY: "auto",
		top: isTablet
			? `${topHeaderHeight.tablet}px`
			: `${topHeaderHeight.desktop}px`,
		left: isPhone ? `0` : `calc((100% - ${breakpointWidth}px)/2)`,
		maxHeight: `calc((100dvh - ${topHeaderHeight.desktop}px) - var(--size-grid))`,
		zIndex: 999,
		borderTopRightRadius: 0,
		borderTopLeftRadius: 0,
	};

	// #####################################################

	return (
		<Dropdown
			isOpen={isOpen}
			close={(insideUseEffect) => {
				extendedClose(insideUseEffect);
			}}
			useLayerOptions={useLayerOptions}
			customLayerStyle={customLayerStyle}
			withMobileVersion
			isParentComponent={true}
			menuContent={() => <HamburgerMenuContent close={extendedClose} />}
		>
			<DropdownTriggerContext.Consumer>
				{({ triggerProps }) => (
					<HamburgerMenuButton
						triggerProps={triggerProps}
						active={isOpen}
						toggle={extendedToggle}
						startPreload={() => {
							HamburgerMenuContent.preload();
						}}
					/>
				)}
			</DropdownTriggerContext.Consumer>
		</Dropdown>
	);
};

export default HamburgerMenu;

// #####################################################
