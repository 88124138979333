// ** Third Party Components
import styled from "styled-components";

// ** Styles Import
import { Container } from "styles/components/Container.styled";
import { breakpoints } from "styles/grid";

// #####################################################

export const StyledInnerContainerGrid = styled(Container)`
	height: 100%;
	width: 100%;
	display: flex;

	@media (max-width: ${breakpoints.phone - 1}px) {
		padding: 0.5rem 0;
	}

	@media (min-width: ${breakpoints.tablet}px) {
		padding: 0.6rem 0;
	}

	@media (min-width: ${breakpoints.desktop}px) {
		padding: 0.75rem 0;
	}
`;

// #####################################################
