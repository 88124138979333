// ** React Imports
import { useContext } from "react";
import { matchPath, useLocation } from "react-router-dom";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import styled from "styled-components";

// ** Custom Component
import TopMenuButton from "./components/TopMenuButton";
import Link from "components/layout/Link";
import SearchTopNavigationButton from "./components/SearchTopNavigationButton";

// ** Custom Hooks
import useOpenClose from "hooks/useOpenClose";

// ** Utils & Helpers
import { searchSexCustomSort } from "utils/searchSexCustomSort";

// ** Icons
import { ReactComponent as SolidUserCircle } from "resources/icons/solid/user-circle.svg";
import { ReactComponent as RegularUserCircle } from "resources/icons/regular/user-circle-regular.svg";
import { ReactComponent as SolidImage } from "resources/icons/solid/image-solid.svg";
import { ReactComponent as RegularImage } from "resources/icons/regular/image-regular.svg";
import { ReactComponent as SolidLocationArrow } from "resources/icons/solid/location-arrow-solid.svg";
import { ReactComponent as RegularLocationArrow } from "resources/icons/regular/location-arrow-regular.svg";
import { ReactComponent as SolidHome } from "resources/icons/solid/home-solid.svg";
import { ReactComponent as RegularHome } from "resources/icons/regular/home-regular.svg";
import { ReactComponent as SolidFilmAlt } from "resources/icons/solid/film-alt-solid.svg";
import { ReactComponent as RegularFilmAlt } from "resources/icons/regular/film-alt-regular.svg";

// ** Constants
import { topNavMenuHeight } from "styles/declares";

// ** Context
import authContext from "contexts/authContext";

// ** Routes
import { initialQuery } from "containers/Users/initialQuery";
import generateQuery from "utils/generateQuery";

// #####################################################

const TopMenuWrapper = styled.div`
	position: relative;
	z-index: 503;
	height: ${topNavMenuHeight}px;
	width: 100%;
	border-bottom: 2px solid var(--color-border);
	border-top: 2px solid var(--color-border);
	background-color: var(--dz-sys-card-background-color);
	box-shadow: ${({ theme: { isDarkMode } }) =>
		isDarkMode
			? "0 1px 3px 0 rgb(0 0 0 / 0.2), 0 1px 2px -1px rgb(0 0 0 / 0.2)"
			: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"};
`;

const TopMenuGrid = styled.div`
	margin-left: auto;
	margin-right: auto;
	display: grid;
	height: 100%;
	max-width: 599px;
	grid-template-columns: repeat(6, minmax(0, 1fr));
	font-weight: 500;
`;

// #####################################################

const TopMenu = ({ locationPathname }) => {
	const isAuth = useContext(authContext);

	const userSearchSex = useSelector((state) => state.global.user.searchSex);
	const userUrlSearch = useSelector((state) => state.global.user.urlSearch);
	const userCityId = useSelector((state) => state.global.user.city);
	const userRegionId = useSelector((state) => state.global.user.region);
	const userCountryCode = useSelector((state) => state.global.user.country);

	const homePageLink = {
		pathname: "/",
		state: { forceReload: true },
	};

	const usersQuery = generateQuery({
		...initialQuery,
		sex:
			userSearchSex?.sort(searchSexCustomSort).join("-") ||
			"woman-man-couple-transsexual",
	});

	const usersLink = {
		pathname: isAuth
			? `/search/${userUrlSearch}`
			: userUrlSearch
			? `/search/${userUrlSearch}`
			: "/search",
		search: isAuth ? usersQuery : undefined,
		state: { forceReload: true },
	};

	const imagesLink = {
		pathname: "/galleries/photos",
		state: { forceReload: true },
	};

	const videosLink = {
		pathname: "/galleries/videos",
		state: { forceReload: true },
	};

	const nearbyQuery = generateQuery({
		city: userRegionId ? userCityId : "any",
		region: userRegionId || "any",
		country: userCountryCode || "",
		distance: 50,
		sex:
			userSearchSex && userSearchSex.length > 0
				? userSearchSex.sort(searchSexCustomSort).join("-")
				: "woman-man-couple-transsexual",
	});

	const nearbyLink = {
		pathname: `/nearby`,
		search: isAuth ? nearbyQuery : undefined,
		state: { forceReload: true },
	};

	// #####################################################
	return (
		<TopMenuWrapper>
			<TopMenuGrid>
				<Link to={homePageLink}>
					<TopMenuButton
						icon={RegularHome}
						iconActive={SolidHome}
						isActive={matchPath(locationPathname, {
							path: "/",
							exact: true,
						})}
					/>
				</Link>
				<Link to={usersLink}>
					<TopMenuButton
						icon={RegularUserCircle}
						iconActive={SolidUserCircle}
						isActive={matchPath(locationPathname, {
							path: "/search/",
						})}
					/>
				</Link>
				<Link to={imagesLink}>
					<TopMenuButton
						icon={RegularImage}
						iconActive={SolidImage}
						isActive={matchPath(locationPathname, {
							path: `(/galleries/photos)`,
							exact: true,
						})}
					/>
				</Link>
				<Link to={videosLink}>
					<TopMenuButton
						icon={RegularFilmAlt}
						iconActive={SolidFilmAlt}
						isActive={matchPath(locationPathname, {
							path: `(/galleries/videos)`,
							exact: true,
						})}
					/>
				</Link>
				<Link to={nearbyLink}>
					<TopMenuButton
						icon={RegularLocationArrow}
						iconActive={SolidLocationArrow}
						isActive={matchPath(locationPathname, {
							path: "/nearby",
						})}
					/>
				</Link>

				<SearchTopNavigationButton />
			</TopMenuGrid>
		</TopMenuWrapper>
	);
};

// #####################################################

export default TopMenu;

// #####################################################
