// ** Third Party Components
import styled from "styled-components";

// ** Constants
import { topHeaderHeight } from "styles/declares";

// ** Styles Import
import { breakpoints } from "styles/grid";

// #####################################################

export const StyledOuterContainer = styled("div")`
	width: 100%;
	margin-right: auto;
	margin-left: auto;

	position: fixed;
	z-index: 500;
	background-color: var(--dz-sys-card-background-color);

	box-shadow: var(--dz-sys-card-shadow);

	height: ${topHeaderHeight.mobile}px;

	@media (min-width: ${breakpoints.tablet}px) {
		height: ${topHeaderHeight.tablet}px;
	}

	@media (min-width: ${breakpoints.desktop}px) {
		height: ${topHeaderHeight.desktop}px;
	}
`;

// #####################################################
