// ** React Imports
import { forwardRef, useContext } from "react";

// ** Store & Actions
import { connect } from "react-redux";

// ** Third Party Components
import loadable from "@loadable/component";

// ** Custom Components
import Block from "components/layout/Block";
import Spacer from "components/layout/Spacer";
import Logo from "./components/Logo";
const RightButtonsSection = loadable(
	() => import("./components/RightButtonsSection"),
	{
		ssr: false,
	}
);
import TopMenu from "components/layout/TopMenu/TopMenu";
import HamburgerMenu from "./components/HamburgerMenu/HamburgerMenu";

// ** Styled Components
import {
	StyledOuterContainer,
	StyledInnerContainer,
	StyledInnerBlock,
} from "./styles";
import { StyledInnerGrid } from "./styles/InnerGrid.styled";
import { StyledInnerContainerGrid } from "./styles/InnerContainerGrid.styled";

// ** Context
import DeviceTypeContext from "contexts/deviceTypeContext";

// #####################################################

const Header = forwardRef(
	(
		{
			userFrozen,
			userId,
			messagesCount,
			notificationsCount,
			locationPathname,
		},
		ref
	) => {
		const { isDesktop } = useContext(DeviceTypeContext);

		// #####################################################

		return (
			<StyledOuterContainer ref={ref} as="header">
				{!isDesktop ? (
					<>
						<StyledInnerContainerGrid>
							<StyledInnerGrid>
								<Block flex justifyCenter>
									<HamburgerMenu />
								</Block>
								<Block>
									<Logo isUserFrozen={userFrozen} />
								</Block>
								<RightButtonsSection
									userFrozen={userFrozen}
									messagesCount={messagesCount}
									notificationsCount={notificationsCount}
									userId={userId}
									locationPathname={locationPathname}
								/>
							</StyledInnerGrid>
						</StyledInnerContainerGrid>

						{!isDesktop && (
							<TopMenu locationPathname={locationPathname} />
						)}
					</>
				) : (
					<StyledInnerContainer>
						<StyledInnerBlock>
							{/* Left Section - Hamburger Menu + Logo */}
							<Block flex fullHeight>
								<Logo isUserFrozen={userFrozen} />
							</Block>
							<Spacer />
							{/* Right Section - Search + Messages + Notification + User Menu */}
							<RightButtonsSection
								userFrozen={userFrozen}
								messagesCount={messagesCount}
								notificationsCount={notificationsCount}
								userId={userId}
							/>
						</StyledInnerBlock>
					</StyledInnerContainer>
				)}
			</StyledOuterContainer>
		);
	}
);

// #####################################################

const mapStateToProps = ({
	global: {
		user: { frozen, id },
		statisticsSelf: { messages, notifications },
	},
}) => ({
	userFrozen: frozen,
	userId: id,
	messagesCount: messages,
	notificationsCount: notifications,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
	Header
);

// #####################################################
