// ** Third Party Components
import styled from "styled-components";

// ** Styles Import
import { breakpoints } from "styles/grid";

// #####################################################

export const StyledInnerGrid = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 2fr 1fr 1fr 1fr;

	@media (min-width: ${breakpoints.desktop}px) {
		padding: 0px 0px 0 1.5rem;
	}
`;

// #####################################################
