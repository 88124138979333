// ** React Imports
import { useContext } from "react";

// ** Contexts
import deviceTypeContext from "contexts/deviceTypeContext";

// #####################################################

const useBreakpointWidth = () => {
	const { isTablet, isDesktop, isGiant } = useContext(deviceTypeContext);

	// #####################################################

	return isTablet ? 600 : isDesktop && !isGiant ? 1050 : 1340;
};

export default useBreakpointWidth;

// #####################################################
