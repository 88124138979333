// ** React Imports
import { useHistory } from "react-router-dom";

// ** Redux
import { useSelector } from "react-redux";

// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Styled Components
import { ButtonRoundedHeader } from "components/layout/Button/ButtonRoundedHeader";
import { StyledHeaderButtonIcon } from "components/global/Header/styles";

// ** SVG Static Imports
import { ReactComponent as LightBars } from "resources/icons/light/bars.svg";
import { ReactComponent as SolidBars } from "resources/icons/solid/bars-solid.svg";

// #####################################################

const HamburgerMenuButton = ({
	triggerProps,
	toggle,
	active,
	userFrozen = false,
	startPreload,
}) => {
	const history = useHistory();
	const { t } = useTranslation(["pages"]);

	const isDarkMode = useSelector((state) => state.global.darkMode.darkMode);

	// #####################################################

	return (
		<div {...triggerProps}>
			<ButtonRoundedHeader
				onClick={() => {
					if (userFrozen) {
						history.push("/userpanel/settings/account");
					} else {
						toggle();
					}
				}}
				onTouchStart={() => {
					if (typeof startPreload === "function") {
						startPreload();
					}
				}}
				onMouseEnter={() => {
					if (typeof startPreload === "function") {
						startPreload();
					}
				}}
				active={active}
				aria-haspopup="true"
				label={t("pages:search")}
			>
				{active ? (
					<StyledHeaderButtonIcon
						type="fas"
						svgIconComponent={SolidBars}
						icon="bars-solid"
						color={isDarkMode ? "primaryLight" : "primary"}
					/>
				) : (
					<StyledHeaderButtonIcon
						type="fal"
						svgIconComponent={LightBars}
						icon="bars"
					/>
				)}
			</ButtonRoundedHeader>
		</div>
	);
};

export default HamburgerMenuButton;
