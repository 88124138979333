// ** React Imports
import { useContext } from "react";

// ** Store & Actions
import { useSelector } from "react-redux";

// ** Custom Components
import Dropdown from "components/layout/Dropdown";
import TopMenuItem from "./TopMenuButton";

// ** Custom Hooks
import useDetectKeyboardOpen from "hooks/useDetectKeyboardOpen";
import useBreakpointWidth from "hooks/useBreakpointWidth";

// ** Contexts
import deviceTypeContext from "contexts/deviceTypeContext";
import { DropdownTriggerContext } from "components/layout/Dropdown/DropdownTriggerContext";

// ** Constants
import { topHeaderHeight, topNavMenuHeight } from "styles/declares";

// ##################################################################

const MAX_DESKTOP_WIDTH = "425px";

// ##################################################################

const ExpandableTopNavigationButton = ({
	icon,
	iconActive,
	label,
	isOpen,
	close,
	toggle,
	menuContent,
}) => {
	const { isPhone, isTablet } = useContext(deviceTypeContext);

	const isClient = useSelector((state) => state.global.isClient);

	const { isKeyboardOpen, visualViewportHeight } = useDetectKeyboardOpen();

	const breakpointWidth = useBreakpointWidth();

	const closeAndCollapse = ({ insideUseEffect = false } = {}) => {
		if (insideUseEffect) {
			close();
		} else {
			close(() => {
				if (document.body.style.overflow === "hidden") {
					document.body.style.overflow = "unset";
				}
			});
		}
	};

	const extendedToggle = () => {
		toggle(() => {
			if (
				!isOpen &&
				isPhone &&
				!isTablet &&
				document.body.style.overflow !== "hidden"
			) {
				document.body.style.overflow = "hidden";
			} else if (isOpen && document.body.style.overflow === "hidden") {
				document.body.style.overflow = "unset";
			}
		});
	};

	const useLayerOptions = {
		onOutsideClick: () => {
			!isPhone && typeof close === "function" && extendedToggle();
		},
		placement: "bottom-center",
		triggerOffset: 0,
		container: isClient ? "app" : "root",
	};

	const customLayerStyle = {
		position: "fixed",
		width: "100%",
		maxWidth: isPhone ? "100%" : MAX_DESKTOP_WIDTH,
		overflowY: "auto",
		right: isPhone ? `0` : `calc((100% - ${breakpointWidth}px)/2)`,
		left: "unset",
		top: `${
			isPhone
				? topHeaderHeight.mobile + topNavMenuHeight
				: topHeaderHeight.tablet + topNavMenuHeight
		}px`,
		maxHeight: `calc((100dvh - ${topHeaderHeight.desktop}px - ${topNavMenuHeight}px) - var(--size-grid))`,
		zIndex: 498,
		backgroundColor: "var(--dz-sys-card-background-color)",
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
	};

	// ##################################################################

	return (
		<Dropdown
			isOpen={isOpen}
			close={(insideUseEffect) => {
				closeAndCollapse(insideUseEffect);
			}}
			useLayerOptions={useLayerOptions}
			customLayerStyle={customLayerStyle}
			isKeyboardOpen={isKeyboardOpen}
			visualViewportHeight={visualViewportHeight}
			withMobileVersion
			isParentComponent
			menuContent={menuContent}
		>
			<DropdownTriggerContext.Consumer>
				{({ triggerProps }) => (
					<TopMenuItem
						label={label}
						icon={icon}
						iconActive={iconActive}
						isActive={isOpen}
						triggerProps={triggerProps}
						onClick={extendedToggle}
					/>
				)}
			</DropdownTriggerContext.Consumer>
		</Dropdown>
	);
};

// ##################################################################

export default ExpandableTopNavigationButton;

// ##################################################################
