// ** React Imports
import { useContext, useState } from "react";

// ** Third Party
import loadable from "@loadable/component";

// ** Custom Components
import ExpandableTopNavigationButton from "./ExpandableTopNavigationButton";

// ** Custom Hooks
import useOpenClose from "hooks/useOpenClose";
import useMobileDetect from "hooks/useMobileDetectHook";
import useDetectKeyboardOpen from "hooks/useDetectKeyboardOpen";

// ** Icons
import { ReactComponent as RegularSearch } from "resources/icons/regular/search.svg";
import { ReactComponent as SolidSearch } from "resources/icons/solid/search-solid.svg";

// ** Context
import deviceTypeContext from "contexts/deviceTypeContext";

// ** Components
import Block from "components/layout/Block";
import Loader from "components/layout/Loader";

// ** Loadable Components
const SearchContent = loadable(
	() =>
		import(
			"components/global/Header/components/Search/components/SearchContent"
		),
	{
		ssr: false,
	}
);

// #####################################################
const SearchTopNavigationButton = () => {
	const { isOpen, close, toggle } = useOpenClose();

	const { isPhone } = useContext(deviceTypeContext);
	const mobileDetect = useMobileDetect();
	const isIos = mobileDetect.isIos();

	const { isKeyboardOpen, visualViewportHeight } = useDetectKeyboardOpen();
	const [isExtended, setIsExtended] = useState(false);

	const closeAndCollapse = ({ insideUseEffect = false } = {}) => {
		if (isExtended) {
			setIsExtended(false);
		}
		if (insideUseEffect) {
			close();
		} else {
			close(() => {
				if (document.body.style.overflow === "hidden") {
					document.body.style.overflow = "unset";
				}
			});
		}
	};

	// #####################################################

	return (
		<ExpandableTopNavigationButton
			icon={RegularSearch}
			iconActive={SolidSearch}
			isOpen={isOpen}
			close={close}
			toggle={toggle}
			menuContent={() => (
				<SearchContent
					fallback={
						<Block flex justifyCenter alignCenter height={40}>
							<Loader size={20} />
						</Block>
					}
					isPhone={isPhone}
					isIos={isIos}
					close={closeAndCollapse}
					isKeyboardOpen={isKeyboardOpen}
					visualViewportHeight={visualViewportHeight}
					setIsExtended={setIsExtended}
					isExtended={isExtended}
				/>
			)}
		/>
	);
};

// #####################################################

export default SearchTopNavigationButton;

// #####################################################
