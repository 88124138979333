// ** Third Party Components
import styled from "styled-components";
// ** Custom Components
import Icon from "components/global/Icon";

// #####################################################

export const StyledHeaderButtonIcon = styled((props) => <Icon {...props} />)`
	display: flex;
	font-size: 21px;
	padding-top: 1px;
`;

// #####################################################
