import { createElement } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Pill = styled(({ squared, margin, outlined, rightPosition, ...props }) =>
	createElement("span", props)
)`
	line-height: 10px;
	font-size: 10px;

	min-width: 17px;
	height: 17px;

	${({ rightPosition }) =>
		rightPosition
			? css`
					right: 24px;
			  `
			: css`
					right: 22px;
			  `}

	${({ margin }) =>
		margin &&
		css`
			margin-left: 0.25rem;
		`}

	${({ squared }) =>
		squared
			? css`
					border-radius: 3px;
					padding: 2px 3px;
			  `
			: css`
					border-radius: 17px;
					padding: 4px 6px;
			  `}


	${({ outlined }) =>
		outlined
			? css`
					color: ${({ color, theme: { colors } }) => colors[color]};
					border: 1px solid
						${({ color, theme: { colors } }) => colors[color]};
			  `
			: css`
					color: ${({ theme: { colors, isDarkMode } }) =>
						isDarkMode ? colors.black : colors.whiteTrue};
					background-color: ${({
						color,
						theme: { colors, isDarkMode },
					}) =>
						isDarkMode && color !== "red"
							? colors.inputBorderHover
							: colors[color]};
			  `}
`;

Pill.defaultProps = {
	color: "primary",
};

Pill.propTypes = {
	color: PropTypes.string,
};

Pill.displayName = "Pill";

export default Pill;
