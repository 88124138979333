// ** React Imports
import { useContext } from "react";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Custom Components
import Link from "components/layout/Link";
import Block from "components/layout/Block";

// ** Custom Hooks
import useBreakpoint from "hooks/useBreakpoint";

// ** Styled Components
import { StyledHeaderLogo } from "../styles";

// ** Context
import authContext from "contexts/authContext";

// #####################################################

const Logo = ({ isUserFrozen }) => {
	const isPhoneSizeM = useBreakpoint("phoneM", "max");

	const isDarkMode = useSelector((state) => state.global.darkMode.darkMode);

	const isAuth = useContext(authContext);

	// #####################################################

	return (
		<Block flex alignCenter>
			{isAuth && isUserFrozen ? (
				<Link to="/userpanel/settings/account">
					<StyledHeaderLogo
						isPhoneSizeM={isPhoneSizeM}
						mode={isDarkMode ? "white" : "blue"}
					/>
				</Link>
			) : (
				<Link to="/" refresh>
					<StyledHeaderLogo
						isPhoneSizeM={isPhoneSizeM}
						mode={isDarkMode ? "white" : "blue"}
					/>
				</Link>
			)}
		</Block>
	);
};

export default Logo;

// #####################################################
