// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledDivSearch = styled("div")`
	width: 425px;
	position: absolute;
	top: 100%;
	${({ right }) =>
		right &&
		`
		right: ${right}px;
	`}
	z-index: 400;
	overflow-y: auto;
	max-height: calc(100vh - 68px - 1rem);
	background-color: ${({ theme: { colors } }) => colors.whiteTrue};
	${({ mobile }) =>
		mobile &&
		`
		right: 0%;
		left: 0%;
		max-width: 100%;
		width: 100%;
		max-height: calc(100vh - 50px);
	`}
`;

// #####################################################
