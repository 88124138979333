// ** Third Party Components
import styled, { css } from "styled-components";

// ** Styles Import
import { breakpoints } from "styles/grid";

// ** Custom Components
import Logo from "components/global/Logo/Logo";

// #####################################################

export const StyledHeaderLogo = styled(Logo).attrs(({ mode, ...props }) => ({
	mode,
	...props,
}))`
	width: auto;
	margin-top: 3px;
	vertical-align: top;
	margin-right: 0.5rem;
	// Na max-width jest -1 pixel, żeby nie robiły się bugi na breakpointach np. 600/1050px, więcej w tasku DZ-2263.
	@media (max-width: ${breakpoints.phone - 1}px) {
		height: 28px;
		margin-left: 0.5rem;
	}

	@media (min-width: ${breakpoints.tablet}px) {
		height: 34px;
		margin-left: 0.5rem;
	}

	@media (min-width: ${breakpoints.desktop}px) {
		height: 34px;
		margin-left: 0;
	}

	${({ isPhoneSizeM }) =>
		isPhoneSizeM &&
		css`
			width: 90%;
			margin-left: 0rem;
			min-width: 40px;
		`}
`;

// #####################################################
